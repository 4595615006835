import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import NavHero from '../components/navigation/NavHero';
import { WithUnderline } from '../styles/link';
import { themeColor, color } from '../styles/theme';
import { MainNav } from '../components/navigation/MainNav';

const Header = styled.header`
  padding: 20px 15px;
  min-height: 78px;
  background-color: ${themeColor('primary')};
  
  @media (min-width: 768px) {
    min-height: 88px;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
  max-width: 1148px;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 15px;
  min-height: calc(100vh - 220px);
  color: ${themeColor('primary')};
`;

const LinkStyled = styled(WithUnderline)`
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  color: ${color('turquoise-blue')}
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header>
      <HeaderInner>
        <NavHero />
        <MainNav />
      </HeaderInner>
    </Header>
    <Content>
      <div css={{ textAlign: 'center' }}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          <LinkStyled to="/">
            Go to the Home
          </LinkStyled>
        </p>
      </div>
    </Content>
  </Layout>
);

export default NotFoundPage;
